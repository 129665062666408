import { GetServerSideProps, NextPage } from "next";
import App, { AppContext, AppProps } from "next/app";
import { useRouter } from "next/router";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { useSubaccountsQuery } from "@saas/account/data";
import {
  prefetchUseSessionCookieValueQuery,
  prefetchUseSessionQuery,
  removeUseSessionQuery,
  SESSION_COOKIE_KEY,
} from "@saas/account/data-access";
import {
  AccountMenu,
  AccountVerificationGuard,
  RouteGuard,
  UnverifiedAccountBlockerModal,
  usePermissions,
  useSessionProfile,
  VerificationNotification,
} from "@saas/account/feature";
import { trackAccountType } from "@saas/account/utils";
import {
  marketRoutes,
  publicRoutes,
  routeKeyLabelV2,
  useNavigation,
} from "@saas/config/dashboard";
import { env } from "@saas/config/shared";
import { getUserRoleFromEmail } from "@saas/content/utils";
import {
  initAmplitude,
  initializeGA,
  pageviewGA,
  queryClient,
  setAmplitudeUser,
} from "@saas/core";
import { useGetUnreadChatRooms } from "@saas/crm/feature";
import { prefetchUseFeatureFlagsStatusQuery } from "@saas/flags/data";
import { FlagKeys, useFlagsStatus } from "@saas/flags/utils";
import { UnavailableStockMappingNotification } from "@saas/inventory/feature";
import { Providers } from "@saas/layout/feature";
import { AppLayout, Breadcrumbs, UsetifulScript } from "@saas/layout/ui";
import {
  prefetchMarketplaceConnectionsQuery,
  useMarketplaceConnectionsQuery,
} from "@saas/marketplace-connection/data-access";
import { MarketplaceConnectionBlockerModal } from "@saas/marketplace-connection/feature";
import { AuthorizationExpiredNotification } from "@saas/marketplace-connection/ui";
import { registerNotificationUser } from "@saas/notification/data";
import { PushNotificationListener } from "@saas/notification/feature";
import { useOneSignal } from "@saas/notification/utils";
import { ErrorBoundary } from "@saas/shared/ui";
import {
  Breadcrumb,
  classNames,
  generateBreadcrumbs,
  isTest,
} from "@saas/shared/utils";

import "./styles.css";

import { dehydrate, DehydratedState } from "@tanstack/react-query";
import { getCookie } from "cookies-next";
import qs from "qs";
import { ParsedUrlQuery } from "querystring";
import { useIsClient } from "usehooks-ts";

if (!isTest && env.API_MOCKS_ENABLED) {
  require("/tools/test/msw");
}

if (!isTest && env.WEBSOCKET_CHAT_MOCKS_ENABLED) {
  require("/tools/test/mock-socket");
}

export type NextGetServerSideProps<
  Props = Record<string, unknown>,
  Query extends ParsedUrlQuery = ParsedUrlQuery
> = GetServerSideProps<Props & { dehydratedState: DehydratedState }, Query>;

export type NextPageWithLayout<Props = Record<string, unknown>> =
  NextPage<Props> & {
    getLayout?: (
      page: ReactElement,
      pageProps: Props & { dehydratedState: DehydratedState },
      flags: Record<FlagKeys, boolean>
    ) => ReactElement;
  };

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const DashboardLayout = ({
  children,
  statusCode,
}: {
  children: ReactElement;
  statusCode: number;
}) => {
  const router = useRouter();
  const flags = useFlagsStatus();
  const { profile } = useSessionProfile();
  const isClient = useIsClient();

  const { data: subaccountlist } = useSubaccountsQuery({
    page: 1,
    perPage: 5,
  });

  const { hasPermission, hasParentId } = usePermissions();

  const {
    "core/usetiful": usetifulFlag,
    "order/cashier-r1": orderCashierR1Flag,
    "inventory/basic-stock-r1": inventoryBasicStockR1Flag,
  } = flags;

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(() =>
    generateBreadcrumbs({
      asPath: router.asPath,
      labelGenerator: (path) => routeKeyLabelV2[path],
    })
  );

  const verifiedStatus = useMemo(
    () => (profile?.isVerified ? "verified" : "unverified"),
    [profile?.isVerified]
  );

  const { data: marketplaceStatus } = useMarketplaceConnectionsQuery({
    select: (data) => (data?.length ? "connected" : "none"),
  });

  const { data: chatRooms } = useGetUnreadChatRooms();

  const { navigation } = useNavigation({
    defaultFlags: flags,
  });

  const isOrderCashier = useMemo(
    () => orderCashierR1Flag && router.asPath.includes("/cashier"),
    [orderCashierR1Flag, router.asPath]
  );

  const registerUser = useCallback(
    (oneSignalUserId: string) =>
      registerNotificationUser({
        deviceId: oneSignalUserId,
        deviceType: navigator.userAgent,
        isSubscribed: true,
      }),
    []
  );

  useOneSignal({
    defaultFlags: flags,
    userId: profile?.id,
    onOneSignalUserIdReceived: registerUser,
  });

  useEffect(() => {
    setAmplitudeUser(profile?.id, {
      app_name: "Melaka Web App",
    });

    if (subaccountlist && profile?.id) {
      const haveSubaccount =
        !profile?.parentUserId && subaccountlist.length > 0 ? true : false;

      const accountLevel = !profile?.parentUserId
        ? "parent_account"
        : "sub_account";

      trackAccountType(profile?.id, haveSubaccount, accountLevel);
    }
  }, [profile?.id, profile?.parentUserId, subaccountlist]);

  useEffect(() => {
    if (isClient) {
      const [title] = window.document.title?.split("|") ?? [];

      setBreadcrumbs(
        generateBreadcrumbs({
          title,
          asPath: router.asPath,
          labelGenerator: (path) => routeKeyLabelV2[path],
        })
      );
    }
  }, [isClient, router.asPath]);

  return (
    <>
      {usetifulFlag ? (
        <UsetifulScript
          userId={profile?.id}
          userRole={getUserRoleFromEmail({ email: profile?.email })}
          verifiedStatus={verifiedStatus}
          marketplaceStatus={marketplaceStatus}
          token={env.USETIFUL_TOKEN}
        />
      ) : null}

      <RouteGuard>
        <AccountVerificationGuard>
          <>
            <AppLayout
              accountMenu={AccountMenu}
              marketplaceConnectionBlockerModal={
                MarketplaceConnectionBlockerModal
              }
              navigation={navigation}
              unverifiedAccountBlockerModal={UnverifiedAccountBlockerModal}
              enabledNotificationR2={false}
              isVerified={profile?.isVerified}
              totalUnreadChatRoom={chatRooms?.totalUnread}
              accesses={profile?.accesses}
              hasAccess={hasPermission(router.asPath)}
              hasParentId={hasParentId}
              statusCode={statusCode}
            >
              <div
                className={classNames(
                  isOrderCashier
                    ? "bg-neutral-N0 md:bg-neutral-N100 min-h-screen p-0"
                    : "bg-neutral-N100 min-h-full p-5 md:p-6",
                  "flex flex-1 flex-col gap-6"
                )}
              >
                {profile ? <VerificationNotification /> : null}

                <AuthorizationExpiredNotification />

                {inventoryBasicStockR1Flag ? (
                  <UnavailableStockMappingNotification />
                ) : null}

                {breadcrumbs.length ? (
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                ) : null}

                {children}
              </div>
            </AppLayout>
            <PushNotificationListener />
          </>
        </AccountVerificationGuard>
      </RouteGuard>
    </>
  );
};

const DashboardApp = ({
  Component,
  pageProps,
}: AppPropsWithLayout): ReactElement => {
  useEffect(() => {
    initializeGA();
    pageviewGA();
    initAmplitude();
  }, []);

  const getLayout = useCallback(
    (page, pageProps) => {
      return (
        <Providers dehydratedState={pageProps.dehydratedState}>
          {({ flags }) =>
            Component.getLayout ? (
              Component.getLayout(page, pageProps, flags)
            ) : (
              <DashboardLayout statusCode={pageProps.statusCode}>
                {page}
              </DashboardLayout>
            )
          }
        </Providers>
      );
    },
    [Component]
  );

  return getLayout(
    <ErrorBoundary>
      <Component {...pageProps} />
    </ErrorBoundary>,
    pageProps
  );
};

DashboardApp.getInitialProps = async (context: AppContext) => {
  const { req, res, pathname, err } = context.ctx;

  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  const isServer = req && res;

  if (isServer) {
    const { cookie } = req.headers;
    const sessionCookieValue = getCookie(SESSION_COOKIE_KEY, {
      req,
      res,
    }) as string;

    const isPublicRoute = [...publicRoutes, ...marketRoutes].includes(pathname);

    if (!sessionCookieValue && !isPublicRoute && res.writeHead) {
      const queryString = qs.stringify(
        {
          // If the user is not logged in and visiting private route, we'll redirect them to the login page.
          redirect: req.url !== "/" ? req.url : undefined,
        },
        { addQueryPrefix: true }
      );

      removeUseSessionQuery();
      res.writeHead(307, {
        Location: `/login${queryString}`,
      });

      res.end();
    }

    await Promise.allSettled([
      prefetchUseFeatureFlagsStatusQuery(),
      prefetchUseSessionCookieValueQuery(sessionCookieValue),
      prefetchUseSessionQuery(sessionCookieValue),
      prefetchMarketplaceConnectionsQuery(cookie),
    ]);
  }

  const appProps = await App.getInitialProps(context);

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      dehydratedState: dehydrate(queryClient),
      statusCode: statusCode,
    },
  };
};

export default DashboardApp;
