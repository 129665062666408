import { env } from "@saas/config/shared";

import { ampli, Environment, type IdentifyProperties } from "./_generated";

import { userAgentEnrichmentPlugin } from "@amplitude/plugin-user-agent-enrichment-browser";

const initAmplitude = () => {
  if (!ampli.isLoaded) {
    ampli.load({
      environment: env.APP_ENV as Environment,
      client: {
        apiKey: env.AMPLITUDE_API_KEY,
        configuration: {
          autocapture: {
            attribution: true,
            pageViews: {
              trackHistoryChanges: "pathOnly",
            },
            sessions: false,
            fileDownloads: false,
            formInteractions: false,
            elementInteractions: false,
          },
        },
      },
    });

    const uaPlugin = userAgentEnrichmentPlugin({
      osName: true,
      osVersion: true,
      deviceModel: true,
      deviceManufacturer: true,
    });

    ampli.client.add(uaPlugin);
  }
};

const setAmplitudeUser = (userId?: string, properties?: IdentifyProperties) => {
  ampli.identify(userId, properties);
};

const resetAmplitude = () => {
  ampli.client.reset();
};

export { ampli, initAmplitude, resetAmplitude, setAmplitudeUser };
export default ampli;
