var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"24805"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN =
  process.env.NX_PUBLIC_SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_SAMPLE_RATE =
  process.env.NX_SENTRY_SAMPLE_RATE ??
  process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE;
const SENTRY_ENV = process.env.NX_APP_ENV ?? "production";
const SENTRY_RELEASE =
  process.env.NX_SENTRY_RELEASE ?? process.env.VERCEL_GIT_COMMIT_SHA;
const SENTRY_DEBUG = process.env.NX_SENTRY_DEBUG ?? false;

Sentry.init({
  dsn: SENTRY_DSN ?? "",
  tracesSampleRate: SENTRY_SAMPLE_RATE ? Number(SENTRY_SAMPLE_RATE) : 0,
  environment: SENTRY_ENV,
  release: SENTRY_RELEASE,
  attachStacktrace: true,
  debug: SENTRY_DEBUG,
});
